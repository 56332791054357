import React from 'react';
import {
  ScheduledItemType,
  useAnswerDataSurveyBhbMutation,
  useAnswerSurveyMutation,
  useGetSurveyInstanceLazyQuery,
} from '@fdha/graphql-api-patient';
import { useDialog, useSnackbar } from '@fdha/web-ui-library';
import { Steps } from '@pages';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  buildBhbDataSurveyInputPayload,
  buildSurveyViews,
  getAnswers,
} from '@utils';
import { useAnalytics } from '@fdha/common-hooks';

import { SurveyData } from '../steps/Steps';

import BHBSurveyDialog from './BHBSurveyDialog';

const SurveySteps = () => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { analyticsClient } = useAnalytics();
  const navigate = useNavigate();
  const params = useParams();

  const instanceId = params?.id || '';

  const [answerSurvey] = useAnswerSurveyMutation();
  const [answerDataSurveyBhb] = useAnswerDataSurveyBhbMutation();

  const [getSurvey, { data, loading }] = useGetSurveyInstanceLazyQuery({
    variables: { instanceId, showDataSurveys: true },
    fetchPolicy: 'cache-and-network',
  });

  const isBHB = useMemo(
    () => data?.surveyInstance?.instanceType === ScheduledItemType.Bhb,
    [data?.surveyInstance?.instanceType]
  );
  const dueAtDate = data?.surveyInstance?.due_at || '';

  useEffect(() => {
    getSurvey();
  }, [getSurvey]);

  const handleAnswerSurvey = async (values: any) => {
    const answers = getAnswers(values);

    await answerSurvey({
      variables: {
        instanceId,
        answers,
      },
    });
  };

  const handleAnswerDataSurvey = async (values: any) => {
    const bhbValues = values.bhb;
    const patientBhbAnswer = buildBhbDataSurveyInputPayload(
      instanceId,
      bhbValues.value,
      bhbValues.time,
      bhbValues.dayPeriod,
      dueAtDate
    );
    await answerDataSurveyBhb({
      variables: {
        bhbData: patientBhbAnswer,
      },
    });
    closeDialog();
  };

  const openBHBDialog = (values: any) => {
    openDialogV2({
      title: 'Please review this information is correct before submitting',
      content: <BHBSurveyDialog values={values} date={dueAtDate} />,
      cancelButtonLabel: 'Back',
      confirmButtonLabel: 'Submit',
      handleConfirm: () => handleSubmit(values),
      i18nKeyTitle: 'surveys:bhbSurvey.confirmationDialog.title',
    });
  };

  const handleSubmit = async (values: any) => {
    try {
      let segmentTrackingLog = {};
      if (isBHB) {
        await handleAnswerDataSurvey(values);
        segmentTrackingLog = {
          id: instanceId,
          name: 'BHB',
          type: ScheduledItemType.Bhb,
        };
      } else {
        segmentTrackingLog = {
          id: instanceId,
          name: data?.surveyInstance?.name,
          type: data?.surveyInstance?.instanceType,
        };
        await handleAnswerSurvey(values);
      }
      analyticsClient?.track('Survey Completed', segmentTrackingLog);
      navigate('/surveys', { replace: true });
      showSnackbarV2({
        severity: 'success',
        message: 'Survey completed. Great job!',
        i18nKey: 'surveys:snackbar.surveyCompleted',
      });
    } catch (error) {
      console.error(error);
      showSnackbarV2({
        severity: 'error',
        message: 'Error to answer survey',
        i18nKey: 'surveys:snackbar.errorAnswerSurvey',
      });
    }
  };

  const views = useMemo(() => {
    if (!data?.surveyInstance) {
      return;
    }
    return buildSurveyViews(data.surveyInstance);
  }, [data?.surveyInstance]);

  const surveyData = useMemo(() => {
    let surveyData: SurveyData;

    surveyData = {
      title: data?.surveyInstance?.name,
      date: data?.surveyInstance?.due_at
        ? Number(data?.surveyInstance?.due_at)
        : undefined,
      i18nKeySubtitle: data?.surveyInstance?.due_at
        ? 'surveys:steps.subtitle'
        : '',
      i18nSubtitleParams: {
        date: data?.surveyInstance?.due_at || '',
      },
    };

    return surveyData;
  }, [data?.surveyInstance?.due_at, data?.surveyInstance?.name]);

  return (
    <Steps
      surveyData={surveyData}
      views={views}
      loading={loading}
      handleSubmit={(values) =>
        isBHB ? openBHBDialog(values) : handleSubmit(values)
      }
    />
  );
};

export default SurveySteps;
