import React from 'react';
import { DishType } from '@fdha/graphql-api-patient';
import { QuestionType, FormStatus, QuestionProps } from '@models';
import { SurveyView } from '@utils';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import QuestionHeader from './QuestionHeader';
import {
  AutocompleteView,
  BHBView,
  ConfirmationView,
  DateOptionView,
  DecimalView,
  DishView,
  ExplanationView,
  MultipleChoiceView,
  NumberView,
  OpenTextView,
  ScaleView,
  SingleChoiceView,
  TextSelectView,
  TimeView,
} from './views';

interface StepsFormProps {
  values: any;
  views: SurveyView[];
  view: SurveyView;
  handleNext: () => void;
  setStatus: (status: FormStatus) => void;
}

const StepsForm: FC<StepsFormProps> = ({
  values,
  views,
  view,
  handleNext,
  setStatus,
}) => {
  const { setFieldValue } = useFormikContext<any>();

  const handleChange = (value: any, name?: string) => {
    setFieldValue(name || view.viewProps.id, value);
    setStatus(FormStatus.CHANGED);
  };

  const getContent = () => {
    if (view.type === 'explanation') {
      return <ExplanationView {...view.viewProps} />;
    }

    if (view.type === 'confirmation') {
      const questions: QuestionProps[] = [];

      views.forEach((view) => {
        if (view.type === 'question') {
          questions.push(view.viewProps);
        }
      });

      return <ConfirmationView questions={questions} answers={values} />;
    }

    if (view.type === 'question') {
      switch (view.viewProps?.type) {
        case QuestionType.OpenText:
          return (
            <OpenTextView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        case QuestionType.DateOption:
          return (
            <DateOptionView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        case QuestionType.MultipleChoice:
          return (
            <MultipleChoiceView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        case QuestionType.TextSelect:
          return (
            <TextSelectView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        case QuestionType.SingleChoice:
        case QuestionType.Binary:
          return (
            <SingleChoiceView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        case QuestionType.Number:
          return (
            <NumberView question={view.viewProps} handleChange={handleChange} />
          );
        case QuestionType.Scale:
          return (
            <ScaleView question={view.viewProps} handleChange={handleChange} />
          );
        case QuestionType.Autocomplete:
          return (
            <AutocompleteView
              question={view.viewProps}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          );
        case QuestionType.BHB:
          return (
            <BHBView question={view.viewProps} handleChange={handleChange} />
          );
        case QuestionType.Meal: {
          return (
            <DishView
              question={view.viewProps}
              dishType={DishType.Meal}
              onChange={handleChange}
              onNext={handleNext}
            />
          );
        }
        case QuestionType.Snack: {
          return (
            <DishView
              question={view.viewProps}
              dishType={DishType.Snack}
              onChange={handleChange}
              onNext={handleNext}
            />
          );
        }
        case QuestionType.Time:
          return (
            <TimeView question={view.viewProps} handleChange={handleChange} />
          );
        case QuestionType.Decimal:
          return (
            <DecimalView
              question={view.viewProps}
              handleChange={handleChange}
            />
          );
        default:
          return;
      }
    }

    return null;
  };

  return (
    <>
      {view.type === 'question' && view.viewProps.type !== QuestionType.BHB && (
        <QuestionHeader
          title={view.viewProps.title}
          i18nKeyTitle={view.viewProps?.i18nKeyTitle}
          subtitle={view.viewProps?.subtitle}
          i18nKeySubtitle={view.viewProps?.i18nKeySubtitle}
          showHipaaSubtitle={view.viewProps?.showHipaaSubtitle}
          showRequired={view.viewProps?.required}
        />
      )}
      {getContent()}
    </>
  );
};

export default StepsForm;
