import { UserType } from '@fdha/web-ui-library';
import { useFeatureFlag } from '@fdha/common-hooks';
import { useGetProfileQuery } from '@fdha/graphql-api-patient';

import { useNotifications } from './useNotifications';

interface MainRoute {
  value: string;
  label: string;
  i18nKeyLabel?: string;
  to: string;
  activeIcon: string;
  inactiveIcon: string;
  'data-testid': string;
  notificationCount?: number;
}

export const useGetMainRoutes = () => {
  const { isFeatureEnabled } = useFeatureFlag();
  const { count } = useNotifications();

  const { data: userData } = useGetProfileQuery();

  const showFoodOps = isFeatureEnabled('show_food_ops');
  const showExplore = isFeatureEnabled('show_explore');

  const showMeals =
    userData?.me.type === UserType.ClinicalTrialPatient && showFoodOps;

  const mainRoutes: MainRoute[] = [
    {
      value: 'surveys',
      label: 'Surveys',
      i18nKeyLabel: 'home:card.surveys.label',
      to: '/surveys',
      activeIcon: 'pie-chart',
      inactiveIcon: 'pie-chart-outline',
      'data-testid': 'SURVEYS_PAGE',
    },
    {
      value: 'learning',
      label: 'Learning',
      i18nKeyLabel: 'home:card.learning.label',
      to: showExplore ? '/learning' : '/courses',
      activeIcon: 'book-open',
      inactiveIcon: 'book-open-outline',
      'data-testid': 'LEARNING_PAGE',
      notificationCount: count.learning,
    },
    ...(showMeals
      ? [
          {
            value: 'meals',
            label: 'Meals',
            i18nKeyLabel: 'home:card.meals.label',
            to: '/meals',
            activeIcon: 'clipboard',
            inactiveIcon: 'clipboard-outline',
            'data-testid': 'MEALS_PAGE',
          },
        ]
      : []),
  ];

  return { mainRoutes };
};
