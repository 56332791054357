import { NetworkStatus } from '@apollo/client';
import { Loader } from '@fdha/web-ui-library';
import { useGetLearningModuleQuery } from '@fdha/graphql-api-patient';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CONTENT_HEIGHT } from '@utils';
import React, { useMemo } from 'react';
import { DefaultBasePage } from '@components';

import CourseContent from './CourseContent';
import CourseQuiz from './CourseQuiz';
import { buildCourseItems } from './utils';

const CourseItem = () => {
  const params = useParams();
  const moduleId = params.moduleId || '';
  const itemId = params.itemId || '';

  const navigate = useNavigate();

  const { data, networkStatus } = useGetLearningModuleQuery({
    variables: { learningModuleId: moduleId },
    notifyOnNetworkStatusChange: true,
  });

  const isLoading = networkStatus === NetworkStatus.loading;
  const isAssigned = data?.learningModule?.is_assigned;

  const courseItems = useMemo(() => {
    if (data?.learningModule) {
      return buildCourseItems(data.learningModule);
    }
    return [];
  }, [data?.learningModule]);

  const index = courseItems.findIndex(
    ({ id, data }) =>
      id === itemId || ('questions' in data && data.questions[0].id === itemId)
  );

  const isLastModule = index === courseItems.length - 1;
  const moduleData = courseItems[index];
  const type = moduleData?.type;

  const isCompleted = !!data?.learningModule.itemsStatus?.find(
    (item) => item?.itemId === itemId
  )?.completed;
  const isNextModuleQuiz =
    !isLastModule && courseItems[index + 1].type === 'quiz';
  const isFinalQuiz = isLastModule && type === 'quiz';

  const handleNext = () => {
    if (isLastModule) {
      // Navigate back to Course
      navigate(`../${moduleId}`);
    } else {
      const nextItemId = courseItems[index + 1].id;
      navigate(`../${moduleId}/${nextItemId}`);
    }
  };

  const handlePrevious = () => {
    const previousAllowScreens = courseItems.filter(
      ({ canGoBack }, i) => canGoBack && i < index
    );

    const lastAllowedScreen = previousAllowScreens.pop();
    if (lastAllowedScreen) {
      navigate(`../${moduleId}/${lastAllowedScreen.id}`);
    } else {
      // Navigate back to Course
      navigate(`../${moduleId}`);
    }
  };

  const renderItem = () => {
    if (type === 'content') {
      return (
        <CourseContent
          data={moduleData.data}
          moduleId={moduleId}
          isCompleted={isCompleted}
          nextButton={
            isNextModuleQuiz
              ? { label: 'Start Quiz', i18nKey: 'common:button.startQuiz' }
              : { label: 'Next', i18nKey: 'common:button.next' }
          }
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      );
    } else {
      return (
        <CourseQuiz
          data={moduleData.data}
          itemId={moduleData.itemId}
          moduleId={moduleId}
          isCompleted={isCompleted}
          isFinalQuiz={isFinalQuiz}
          onNext={handleNext}
        />
      );
    }
  };

  if (!isLoading && (!isAssigned || !moduleData)) {
    return <DefaultBasePage type="notFound" />;
  }

  return (
    <Box height={CONTENT_HEIGHT}>{isLoading ? <Loader /> : renderItem()}</Box>
  );
};

export default CourseItem;
