import React from 'react';
import {
  useFeatureFlag,
  useWebSocket,
  WebSocketMessage,
} from '@fdha/common-hooks';
import { createContext, useCallback, useContext, useEffect } from 'react';

import { useLearningNotifications } from './useLearningNotifications';

export type NotificationType = 'learning';

interface Count {
  learning: number;
}
interface NotificationContext {
  count: Count;
  clearNotification: (notificationType: NotificationType) => void;
  isLoading: boolean;
}

const Context = createContext<NotificationContext>({
  count: { learning: 0 },
  clearNotification: () => {},
  isLoading: false,
});

export const NotificationsProvider = ({ ...props }) => {
  const { isFeatureEnabled, isLoading: loadingFeatureFlags } = useFeatureFlag();
  const showLearningContent = isFeatureEnabled('show_learning_content');

  const {
    count: learningCount,
    isLoading: loadingLearning,
    clear: clearLearning,
    refetch: refetchLearning,
  } = useLearningNotifications(!showLearningContent);

  const { addListener, removeListener } = useWebSocket();

  const count = {
    learning: learningCount,
  };

  const isLoading = loadingFeatureFlags || loadingLearning;

  const invalidateCache = useCallback(() => {
    refetchLearning();
  }, [refetchLearning]);

  const handleClearNotification = useCallback(
    (type: NotificationType) => {
      switch (type) {
        case 'learning':
          if (showLearningContent) {
            clearLearning();
          }
          break;
      }
    },
    [clearLearning, showLearningContent]
  );

  const updateNotification = useCallback(
    (message: WebSocketMessage) => {
      if (message.eventType === 'invalidate') {
        invalidateCache();
      }

      if (message.eventType === 'sync') {
        if (message.dataType === 'learning') {
          refetchLearning();
        }
      }
    },
    [invalidateCache, refetchLearning]
  );

  useEffect(() => {
    addListener(updateNotification);

    return () => {
      removeListener(updateNotification);
    };
  }, [addListener, removeListener, updateNotification]);

  return (
    <Context.Provider
      value={{
        count,
        isLoading,
        clearNotification: handleClearNotification,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useNotifications = () => {
  const { count, isLoading, clearNotification } = useContext(Context);

  return { count, isLoading, clearNotification };
};
