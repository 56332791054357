import { DefaultBasePage } from '@components';

import ButtonsBugAndFeedback from './ButtonsBugAndFeedback';

const Help = () => {
  return (
    <DefaultBasePage
      title="Help"
      contentSize="small"
      i18nKeyTitle="profile:help.title"
    >
      <ButtonsBugAndFeedback />
    </DefaultBasePage>
  );
};

export default Help;
