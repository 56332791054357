import {
  useClearLearningBadgeMutation,
  useCountNewLearningAssignmentsQuery,
} from '@fdha/graphql-api-patient';
import { useCallback } from 'react';

export const useLearningNotifications = (skip: boolean) => {
  const {
    data,
    loading: isLoading,
    refetch,
  } = useCountNewLearningAssignmentsQuery({
    skip,
  });
  const count = data?.newLearningAssignments || 0;

  const [clearLearningBadge] = useClearLearningBadgeMutation();

  const handleClear = useCallback(async () => {
    if (!count) {
      return;
    }

    try {
      await clearLearningBadge({
        optimisticResponse: {
          clearLearningBadge: 0,
        },
        update(cache, mutationResult) {
          const clearLearningBadge = mutationResult.data?.clearLearningBadge;

          cache.modify({
            fields: {
              newLearningAssignments(existingValue) {
                return clearLearningBadge ?? existingValue;
              },
            },
          });
        },
      });
    } catch (e) {
      console.log('Unable to clear learning notifications', e);
    }
  }, [clearLearningBadge, count]);

  return {
    count,
    isLoading,
    clear: handleClear,
    refetch: refetch,
  };
};
