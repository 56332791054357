import React, { useMemo } from 'react';
import { DataEmptyState, DefaultBasePage } from '@components';
import { Graph, Typography, formatUTCDate } from '@fdha/web-ui-library';
import { Stack } from '@mui/material';
import { useListBhbAssaysResultsQuery } from '@fdha/graphql-api-patient';
import { getBHBTooltipColor } from '@utils';
import { useTranslation } from '@fdha/common-hooks';

import BHBLevel from './BHBLevel';
import BHBInformation from './BHBInformation';

const BHBTrialData = () => {
  const { translate } = useTranslation();

  const { data, loading } = useListBhbAssaysResultsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const assaysData = data?.getAssays.edges.map((edge) => edge.node);

  const lastItem =
    assaysData && assaysData?.length
      ? assaysData[assaysData.length - 1].value
      : 0;

  const limits = useMemo(() => {
    return {
      upper: assaysData?.[0]?.upperLimit ?? 0,
      bottom: assaysData?.[0]?.bottomLimit ?? 0,
    };
  }, [assaysData]);

  const graphData = useMemo(
    () =>
      assaysData?.map((item) => {
        const date = formatUTCDate(item.eventDate, 'formalMonthDay') || '';

        return {
          x: translate('dataVisualization:graphDate', date, {
            date: item.eventDate,
          }),
          y: item.value.toFixed(1),
          color: getBHBTooltipColor(item.value, limits),
        };
      }) || [],
    [assaysData, limits, translate]
  );

  const showEmptyState = !loading && assaysData?.length === 0;

  return (
    <DefaultBasePage
      i18nKeyTitle="home:trial.title"
      title="Trial data"
      contentSize="small"
      isLoading={loading}
    >
      <Stack spacing={3}>
        <Typography
          showSkeleton={loading}
          i18nKey="dataVisualization:bhb.description"
        >
          In combination with your study drugs, following the insulin
          suppressing diet may help make your study treatment safer and more
          effective at fighting your cancer.
        </Typography>
        {showEmptyState ? (
          <DataEmptyState />
        ) : (
          <>
            <BHBLevel value={lastItem} limits={limits} showSkeleton={loading} />
            <Graph
              data={graphData}
              limits={limits}
              showSkeleton={loading}
              showCard
              yAxisUpperLimit={6.5}
            />
            <Typography
              showSkeleton={loading}
              i18nKey="dataVisualization:bhb.goal"
              i18nParams={{
                bottomLimit: limits?.bottom.toFixed(1),
                upperLimit: limits?.upper.toFixed(1),
              }}
            >
              {
                'The goal range for your <strong>BHB</strong> level is between <strong>{{ bottomLimit }} - {{ upperLimit}} mmol/L</strong>'
              }
            </Typography>
            <BHBInformation
              value={lastItem}
              limits={limits}
              showSkeleton={loading}
            />
          </>
        )}
      </Stack>
    </DefaultBasePage>
  );
};

export default BHBTrialData;
