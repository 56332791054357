import React, { useMemo } from 'react';
import { DefaultBasePage } from '@components';
import {
  useGetFoodProgramUserV2Query,
  useListDeliveriesV2Query,
  FoodProgramStatus,
} from '@fdha/graphql-api-patient';
import { EmptyStateCard, Typography } from '@fdha/web-ui-library';
import { Skeleton, Stack } from '@mui/material';
import {
  getConcatWeekdaysOnDiet,
  getFromDateForDeliveriesQuery,
} from '@fdha/common-utils';
import { useFeatureFlag, useSystemSetting } from '@fdha/common-hooks';

import DeliveryCard from './DeliveryCard';
import WeekSchedule from './WeekSchedule';

const Meals = () => {
  const { data: foodProgramData, loading: loadingFoodProgram } =
    useGetFoodProgramUserV2Query({ fetchPolicy: 'cache-and-network' });

  const { value, isLoading: loadingSystemSetting } = useSystemSetting<number>(
    'offsetForDeliveriesQuery'
  );

  const { data, loading: loadingDeliveries } = useListDeliveriesV2Query({
    variables: {
      date: getFromDateForDeliveriesQuery(value || 0),
    },
    skip: loadingSystemSetting,
    fetchPolicy: 'cache-and-network',
  });
  const { isFeatureEnabled, isLoading: isFeatureFlagLoading } =
    useFeatureFlag();
  const deliveries = data?.deliveriesV2;

  const isLoading =
    loadingFoodProgram ||
    loadingDeliveries ||
    isFeatureFlagLoading ||
    loadingSystemSetting;

  const foodProgramUser = foodProgramData?.foodProgramUserV2;

  const isArchived = foodProgramUser?.status === FoodProgramStatus.Archived;

  const weekdaysOnDiet = useMemo(() => {
    return foodProgramUser?.userDeliveryMenus?.length
      ? getConcatWeekdaysOnDiet(foodProgramUser?.userDeliveryMenus)
      : [];
  }, [foodProgramUser?.userDeliveryMenus]);

  const renderLoading = () => {
    return (
      <>
        <Stack spacing={2} direction="row" sx={{ mb: 3 }}>
          {[...Array(7)].map((_, index) => (
            <Skeleton
              key={`schedule_${index}`}
              variant="rectangular"
              height={78}
              sx={{ flex: 1 }}
            />
          ))}
        </Stack>
        <Stack spacing={2}>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={138} />
          ))}
        </Stack>
      </>
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyStateCard
        title="No upcoming deliveries"
        message="Your upcoming deliveries will show up here according to your schedule."
        i18nKeyTitle="meals:emptyState.title"
        i18nKeyMessage="meals:emptyState.text"
      />
    );
  };

  const renderArchived = () => {
    return (
      <>
        <Typography variant="h5" sx={{ mb: 1 }} i18nKey="meals:archived.title">
          Thank you for participating!
        </Typography>
        <Typography variant="body1" i18nKey="meals:archived.text">
          You are welcome to continue your journey with us in the Faeth App.
          Please reach out to your coach if you have any questions.
        </Typography>
      </>
    );
  };

  return (
    <DefaultBasePage
      i18nKeyTitle="meals:title"
      title="Meals"
      contentSize="small"
    >
      {isLoading ? (
        renderLoading()
      ) : (
        <>
          {isArchived ? (
            renderArchived()
          ) : (
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                i18nKey="meals:plan.title"
              >
                Plan:
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: 1, mb: 3 }}
                i18nKey="meals:plan.text"
              >
                Deliveries will occur Tuesdays and/or Fridays according to your
                pre-defined schedule.
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                pb={1}
                i18nKey="meals:schedule.title"
              >
                This week's schedule:
              </Typography>
              {weekdaysOnDiet && <WeekSchedule selectedDays={weekdaysOnDiet} />}
              {!deliveries?.length || foodProgramUser == null ? (
                renderEmptyState()
              ) : (
                <>
                  {deliveries.map((delivery) => (
                    <DeliveryCard
                      key={delivery.id}
                      delivery={delivery}
                      showShipmentDetails={isFeatureEnabled(
                        'show_shipment_details'
                      )}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </>
      )}
    </DefaultBasePage>
  );
};

export default Meals;
